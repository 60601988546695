@use '@virginmediao2/momentum-css/react/components/form-input';

.form-search-input {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  max-width: 620px;

  div {
    max-width: none;
  }

  input {
    padding-right: 48px;
  }

  /* For Webkit browsers (Chrome, Safari, Edge) */
  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
  }

  /* For Firefox */
  input[type='search']::-moz-search-cancel {
    display: none;
  }
}

.form-search-input-button {
  position: absolute;
  right: 6px;
  top: 6px;
  appearance: none;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 10px;
  transform: rotate(90deg);
  width: max-content;

  &:hover {
    background: transparent;
    cursor: pointer;
    padding: 10px;
    transform: rotate(90deg);
    width: max-content;

    &:hover {
      background: transparent;
    }
  }
}
